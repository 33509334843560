import React from 'react'
import { Link, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import { GatsbyImage } from 'gatsby-plugin-image'

import DefaultLayout from '../components/layout'

import SEO from '../components/seo'

import 'katex/dist/katex.min.css'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark

    return (
      <DefaultLayout>
        <SEO 
          title={post.frontmatter.title} 
          description={post.excerpt}
        />
        <div className="clearfix post-content-box">
          <article className="article-page">
            <div className="page-content">
              {post.frontmatter.img && (
                <div className="page-cover-image">
                  <figure>
                    <GatsbyImage
                      image={
                        post.frontmatter.img.childImageSharp.gatsbyImageData
                      }
                      className="page-image"
                      key={
                        post.frontmatter.img.childImageSharp.gatsbyImageData.src
                      }
                      alt=""
                    />
                  </figure>
                </div>
              )}
              <div className="wrap-content">
                <header className="header-page">
                  <h1 className="page-title">{post.frontmatter.title}</h1>
                  <div className="page-date">
                    <span>{post.frontmatter.date}</span>
                  </div>
                  <div className="page-reading-time">
                    {
                      // Check for published being false rather than true since not all posts are annotated with published, only the false ones  
                      post.frontmatter.published == false ? "" : `${post.timeToRead} minute read`
                    }
                  </div>
                </header>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
                <div className="page-footer">
                  <div className="page-tag">
                    {post.frontmatter.tags &&
                      post.frontmatter.tags.map((tag) => (
                        <span key={tag}>
                          <Link className="tag" to={`/tags/${kebabCase(tag)}/`}>
                            # {tag}
                          </Link>
                        </span>
                      ))}
                  </div>
                </div>
                <p>
                  Joe Fabisevich is an indie developer creating software at <Link to="https://redpanda.club" className="red-link">Red Panda Club Inc.</Link> while writing about design, development, and building a company. Formerly an iOS developer working on societal issues <Link to="https://threads.net/mergesort" className="blue-link">@Twitter</Link>. These days I don't tweet, but I do post on <Link to="https://threads.net/mergesort" className="text-colored-link">Threads</Link>.
                </p>
                <p>
                  Like my writing? You can keep up with it in your favorite <Link to="https://fabisevi.ch/feed.xml">RSS</Link> reader, or get posts <Link to="https://mailchi.mp/3237be1819ae/fabisevich-blog-posts">emailed</Link> in newsletter form. I promise to never spam you or send you anything other than my posts, it's just a way for you to read my writing wherever's most comfortable for you.
                </p>
                <p>
                If you'd like to know more, wanna talk, or need some advice, feel free to sign up for <Link to="https://cal.com/mergesort/office-hours">office hours</Link>, I'm very friendly. 🙂
                </p>
              </div>
            </div>
          </article>
        </div>
      </DefaultLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 500)
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMM D, YYYY")
        tags
        published
        img {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
